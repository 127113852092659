import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { EmptyContainer, CommonLoading } from 'src/components/atom';

const Fallback = forwardRef((props: { fetch: () => Promise<any>; children: ReactNode }, ref) => {
  const { useToast } = useShellRootContext();

  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useImperativeHandle(ref, () => ({
    setError(val) {
      setIsError(val);
    },
  }));

  useEffect(() => {
    setIsFetching(true);
    props
      .fetch()
      .then()
      .catch(() => setIsError(true))
      .finally(() => setIsFetching(false));
  }, []);

  useEffect(() => {
    if (isError) {
      renderError();
    }
  }, [isError]);

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('unable_to_load_data'),
    });
  };

  const enableCondition = !isFetching;

  const memoRenderComponent = useMemo(() => {
    if (isError) {
      return <EmptyContainer retryAction={props.fetch} />;
    }

    if (enableCondition) {
      console.log('[app-overview] render <Overview> ');

      return <>{props.children}</>;
    }

    return <></>;
  }, [enableCondition, isError]);

  return <div>{isFetching ? <CommonLoading /> : memoRenderComponent}</div>;
});

Fallback.displayName = 'Fallback';
export default Fallback;
