import React from 'react';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TDevicesTabProps = {
  appName: string;
  closeModal: () => void;
  hideModal: () => void;
};
const DevicesTab = (props: TDevicesTabProps) => {
  const { stack, appUuid } = useShellRootContext();
  const customInitialColumns = [
    'serialNumber',
    'modelName',
    'status',
    'statusUpdated',
    'connectionState',
    'apps',
    'assessment',
  ];

  return (
    <MfeLoader
      component="@jarvis/react-ecp-devices"
      {...props}
      {...{
        stack,
        customInitialColumns,
        customRelativePath: `/solutions/app/${appUuid}`,
        appUuid,
        appName: props.appName,
        showDevicesGroupCUD: false,
        showDevicesGroupView: false,
        closeDownloadAppConfigModal: props.closeModal,
        hideDownloadAppConfigModal: props.hideModal,
      }}
    />
  );
};
export default DevicesTab;
