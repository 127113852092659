import { extendSystem } from '@veneer/system';
import tokens from '@veneer/tokens';

const color = tokens.color;
// import { Colors as ColorPalette } from 'app/config/color_palettes';

const getWexColors = (colors) => {
  return {
    default: colors['0'],
    hover: colors['1'],
    active: colors['2'],
    backgroundLight: colors['0'] + '0D',
    background: colors['0'] + '1A',
    foreground: colors['3'],
  };
};
const White = '#FFFFFF';
const getContrastWexColors = (colors) => {
  return {
    default: colors['0'],
    hover: colors['1'],
    active: colors['2'],
    backgroundLight: colors.highContrastEigengrau,
    background: colors.highContrastEigengrau,
    foreground: colors['3'],
  };
};

const ColorOverride = {
  wex: {
    light: {
      primary: {
        ...getWexColors([color.darkNavy7, color.darkNavy8, color.darkNavy9, '#ffffff']),
      },
      neutral: {
        ...getWexColors([color.gray7, color.darkNavy8, color.darkNavy9, '#ffffff']),
      },
      positive: {
        ...getWexColors([color.green7, color.green8, color.green9], '#ffffff'),
      },
      negative: {
        ...getWexColors([color.red7, color.red8, color.red9, '#ffffff']),
      },
      warning: {
        ...getWexColors([color.darkOrange5, color.darkOrange6, color.darkOrange7, '#ffffff']),
      },
      foreground: {
        default: color.gray12,
        medium: color.gray9,
        light: color.gray8,
        inverse: '#ffffff',
        disabled: color.gray6,
      },
      background: {
        default: color.gray0,
        container: '#ffffff',
        disabled: color.gray2,
        shell: '#ffffff',
        stroke: color.gray3,
      },
    },

    dark: {
      primary: {
        ...getWexColors([color.darkNavy5, color.darkNavy4, color.darkNavy3, '#212121']),
      },
      neutral: {
        ...getWexColors([color.gray5, color.gray4, color.gray3, '#212121']),
      },
      positive: {
        ...getWexColors([color.green5, color.green4, color.green3, '#212121']),
      },
      negative: {
        ...getWexColors([color.red5, color.red4, color.red3, '#212121']),
      },
      warning: {
        ...getWexColors([color.darkOrange5, color.darkOrange4, color.darkOrange3, '#212121']),
      },
      foreground: {
        default: '#ffffff',
        medium: color.gray3,
        light: color.gray5,
        inverse: color.gray12,
        disabled: color.gray7,
      },
      background: {
        default: color.gray12,
        container: color.gray11,
        disabled: color.gray9,
        shell: color.gray12,
        stroke: color.gray8,
      },
    },

    contrast: {
      primary: {
        ...getContrastWexColors([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      neutral: {
        ...getContrastWexColors([
          color.highContrastYellow,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      positive: {
        ...getContrastWexColors([
          color.green5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      negative: {
        ...getContrastWexColors([
          color.red5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      warning: {
        ...getContrastWexColors([
          color.darkOrange5,
          color.highContrastPink,
          color.highContrastBlue,
          color.highContrastEigengrau,
        ]),
      },
      foreground: {
        default: color.white,
        medium: color.white,
        light: color.white,
        inverse: color.highContrastEigengrau,
        disabled: color.highContrastGray,
      },
      background: {
        default: color.highContrastEigengrau,
        container: color.highContrastEigengrau,
        disabled: color.gray8,
        shell: color.highContrastEigengrau,
        stroke: color.highContrastGray,
      },
    },
  },

  default: {
    light: {
      primary: {
        backgroundLight: 'rgba(2, 120, 171, 0.05)',
        active: '#014667',
      },
    },
    dark: {},
    contrast: {},
  },
};

// Default veneer color paelttes for light and dark theme
const DefaultColor = {
  light: {
    background: {
      default: '#f8f8f8',
      container: '#ffffff',
      disabled: 'rgba(33,33,33,0.05)',
    },
    foreground: {
      default: '#212121',
      medium: '#404040',
      light: '#737373',
      inverse: '#ffffff',
      disabled: '#adadad',
    },
    stroke: {
      default: '#dbdbdb',
    },
    primary: {
      default: '#0278ab',
      hover: '#035c84',
      active: '#014667',
      background: 'rgba(2,120,171,0.1)',
      backgroundLight: 'rgba(2,120,171,0.05)',
      foreground: '#ffffff',
    },
    neutral: {
      default: '#585858',
      hover: '#404040',
      active: '#363636',
      background: 'rgba(88,88,88,0.1)',
      backgroundLight: 'rgba(88,88,88,0.05)',
      foreground: '#ffffff',
    },
    positive: {
      default: '#1c7a17',
      hover: '#135b08',
      active: '#104506',
      background: 'rgba(28,122,23,0.1)',
      backgroundLight: 'rgba(28,122,23,0.05)',
      foreground: '#ffffff',
    },
    warning: {
      default: '#d06702',
      hover: '#b55500',
      active: '#954300',
      background: 'rgba(208,103,2,0.1)',
      backgroundLight: 'rgba(208,103,2,0.05)',
      foreground: '#ffffff',
    },
    negative: {
      default: '#be1313',
      hover: '#a90b0a',
      active: '#8e0302',
      background: 'rgba(190,19,19,0.1)',
      backgroundLight: 'rgba(190,19,19,0.05)',
      foreground: '#ffffff',
    },
  },
  dark: {
    background: {
      default: '#212121',
      container: '#2b2b2b',
      disabled: '#404040',
    },
    foreground: {
      default: '#fff',
      medium: '#d9d9d9',
      light: '#a3a3a3',
      inverse: '#212121',
      disabled: '#666666',
    },
    stroke: {
      default: '#545454',
    },
    primary: {
      default: '#3bb7f6',
      hover: '#73cffe',
      active: '#9fe0fe',
      background: 'rgba(59,183,246,0.1)',
      backgroundLight: 'rgba(59,183,246,0.05)',
      foreground: '#212121',
    },
    neutral: {
      default: '#a3a3a3',
      hover: '#c2c2c2',
      active: '#d9d9d9',
      background: 'rgba(163,163,163,0.1)',
      backgroundLight: 'rgba(163,163,163,0.05)',
      foreground: '#212121',
    },
    positive: {
      default: '#00d072',
      hover: '#4ef5ab',
      active: '#78ffc2',
      background: 'rgba(0,208,114,0.1)',
      backgroundLight: 'rgba(0,208,114,0.05)',
      foreground: '#212121',
    },
    warning: {
      default: '#ff8900',
      hover: '#ffa238',
      active: '#ffb866',
      background: 'rgba(255,137,0,0.1)',
      backgroundLight: 'rgba(255,137,0,0.05)',
      foreground: '#212121',
    },
    negative: {
      default: '#ff6445',
      hover: '#ff8373',
      active: '#ffa59e',
      background: 'rgba(255,100,69,0.1)',
      backgroundLight: 'rgba(255,100,69,0.05)',
      foreground: '#212121',
    },
  },
  contrast: {
    background: {
      default: '#16141D',
      container: '#16141D',
      disabled: '#545454',
    },
    foreground: {
      default: '#fff',
      medium: '#fff',
      light: '#fff',
      inverse: '#16141D',
      disabled: '#ADADAD',
    },
    stroke: {
      default: '#ADADAD',
    },
    primary: {
      default: '#FFF700',
      hover: '#FE6AFF',
      active: '#00D3FF',
      background: '#16141D',
      backgroundLight: '#16141D',
      foreground: '#16141D',
    },
    neutral: {
      default: '#FFF700',
      hover: '#FE6AFF',
      active: '#00D3FF',
      background: '#16141D',
      backgroundLight: '#16141D',
      foreground: '#16141D',
    },
    positive: {
      default: '#00d072',
      hover: '#FE6AFF',
      active: '#00D3FF',
      background: '#16141D',
      backgroundLight: '#16141D',
      foreground: '#16141D',
    },
    warning: {
      default: '#ff8900',
      hover: '#FE6AFF',
      active: '#00D3FF',
      background: '#16141D',
      backgroundLight: '#16141D',
      foreground: '#16141D',
    },
    negative: {
      default: '#ff6445',
      hover: '#FE6AFF',
      active: '#00D3FF',
      background: '#16141D',
      backgroundLight: '#16141D',
      foreground: '#16141D',
    },
  },
};

export const getPalette = (mode = 'light', selectedColor = 'wex') => {
  let _palette = JSON.parse(JSON.stringify(DefaultColor[mode]));
  let _override =
    ColorOverride[selectedColor] && ColorOverride[selectedColor][mode]
      ? ColorOverride[selectedColor][mode]
      : ColorOverride.default[mode];
  if (_override) {
    for (let key in _override) {
      _palette[key] = {
        ..._palette[key],
        ..._override[key],
      };
    }
  }
  return _palette;
};

export const getSystem = (mode = 'light', selectedColor = 'wex') => {
  return extendSystem({
    palette: {
      light: getPalette('light', selectedColor),
      dark: getPalette('dark', selectedColor),
      contrast: getPalette('contrast', selectedColor),
    },
  });
};
