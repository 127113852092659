import styled from 'styled-components';
import React, { ReactNode, useEffect } from 'react';
import { useAccordionHelper } from 'src/veneerHelper';
import { Accordion } from '@veneer/core';

const AccordionMono = (props: { id: string; title: string; children: ReactNode }) => {
  const { accordionState, accordionSetState, accordionFunc } = useAccordionHelper();

  useEffect(() => {
    createAccordionItems();
  }, []);

  const createAccordionItems = () => {
    const updatedAccordion = [
      {
        id: props.id,
        expanded: true,
        header: {
          centralArea: (
            <HeaderWrapper>
              <Title data-testid={`${props.id}-accordion__title`}>{props.title}</Title>
            </HeaderWrapper>
          ),
        },
        content: <>{props.children}</>,
      },
    ];

    accordionSetState.setItems(updatedAccordion);
  };

  return (
    <Wrapper id={`${props.id}__wrapper`}>
      <Accordion
        data-testid={props.id}
        id={props.id}
        items={accordionState.items}
        onExpand={accordionFunc.handleExpand}
        onCollapse={accordionFunc.handleCollapse}
      />
    </Wrapper>
  );
};

export default AccordionMono;

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 20px;
  margin-left: 20px;
  line-height: 28px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  div:nth-child(1) {
    width: min(359px, 100%);
  }

  div:nth-child(2) {
    margin: 0 18px 0 10px;
  }
`;
