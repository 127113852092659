import { createContext } from 'react';

const stack = null;
const localization = null;
const useToast = null;
const events = null;
const authProvider = null;
const navigation = null;
const getBreadcrumb = null;
const setBreadcrumb = null;
const removeBreadcrumb = null;
const tenantId = null;
const showLastUpdatedSection = null;
const customRelativePath = null;
const appUuid = null;
const permissionSet = null;
const setHpProps = null;
const theme = null;
const featureFlags = null;

const shellRootContext = createContext({
  stack,
  localization,
  useToast,
  events,
  authProvider,
  navigation,
  getBreadcrumb,
  setBreadcrumb,
  removeBreadcrumb,
  showLastUpdatedSection,
  tenantId,
  customRelativePath,
  appUuid,
  permissionSet,
  setHpProps,
  theme,
  featureFlags,
});

export default shellRootContext;
