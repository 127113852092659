import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, CustomImage, IconChevronLeft, IconChevronRight } from '@veneer/core';

const Carousel = (props: { data }) => {
  const totalImgCount = props.data.length;
  const IMG = props.data;

  const [currentIdx, setCurrentIdx] = useState(0);
  const slideRef = useRef(null);

  useEffect(() => {
    slideRef.current.style.transition = `all 0.5s ease-in-out`;
    slideRef.current.style.transform = `translateX(-${currentIdx * 343}px)`;
  }, [currentIdx]);

  const nextSlide = () => {
    if (currentIdx >= totalImgCount - 1) {
      setCurrentIdx(0);
    } else {
      setCurrentIdx((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (currentIdx === 0) {
      setCurrentIdx(totalImgCount - 1);
    } else {
      setCurrentIdx((prev) => prev - 1);
    }
  };

  const getSubImages = () => {
    const subImg = [];

    for (let i = 0; i < 5; i++) {
      if (i >= totalImgCount) {
        subImg.push(<Rectangle key={i} />);
      } else {
        subImg.push(
          <Rectangle key={i}>
            <CustomImage customStyle={{ width: '62px', height: '40px' }} src={IMG[i]} />
          </Rectangle>,
        );
      }
    }
    return <>{subImg.map((sub) => sub)}</>;
  };

  return (
    <Wrapper data-testid={'carousel'}>
      <Frame>
        <Button
          data-testid={'img-slider-prev-button'}
          leadingIcon={<IconChevronLeft />}
          onClick={prevSlide}
          appearance="ghost"
          customStyle={{ width: '24px', opacity: '0.8', fill: '#212121' }}
        />
        <Slider>
          <ImageBox ref={slideRef}>
            {IMG.map((el, idx) => (
              <ImageList key={+idx}>
                <CustomImage
                  customStyle={{ width: '343px', height: '221px' }}
                  src={el}
                  data-testid={`carousel-image-${idx}`}
                />
              </ImageList>
            ))}
          </ImageBox>
        </Slider>

        <Button
          data-testid={'img-slider-next-button'}
          leadingIcon={<IconChevronRight />}
          onClick={nextSlide}
          appearance="ghost"
          customStyle={{ width: '24px', opacity: '0.8', fill: '#212121' }}
        />
      </Frame>
      <Frames>{getSubImages()}</Frames>
    </Wrapper>
  );
};

export default Carousel;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Frame = styled.div`
  display: flex;
  width: 421px;
  justify-content: center;
  align-items: center;
`;

const Frames = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const Rectangle = styled.div`
  width: 62px;
  height: 40px;

  background: var(--light-neutral-background, rgba(88, 88, 88, 0.1));
`;

const Slider = styled.div`
  width: 343px;
  overflow: hidden;
  margin: 0 5px;
  display: flex;
`;

const ImageBox = styled.ul`
  width: 343px;
  height: 221px;
  flex-shrink: 0;
  margin: 10px 0 0 0;
  padding: 0;
  display: flex;
  transition: all 0.5s ease-in-out;
  transform: translateX(-343px);

  background: var(--light-neutral-background, rgba(88, 88, 88, 0.1));
`;

const ImageList = styled.li`
  list-style: none;
`;
