import styled from 'styled-components';
import React from 'react';
import { refined } from 'src/utils/refinedMethods';

const BlockText = ({ title, val }) => {
  return (
    <Block>
      <BlockTitle>{title}</BlockTitle>
      <BlockValue>{refined(val)}</BlockValue>
    </Block>
  );
};

export default BlockText;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

const BlockTitle = styled.span`
  color: var(--light-foreground-light, #737373);

  font-family: Forma DJR UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
`;

const BlockValue = styled.span`
  color: var(--light-foreground, #212121);

  font-family: Forma DJR Micro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
`;
