import AssetsProviderFactory from 'src/assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';
import { storeStartRootComponent } from 'src/store/appsOverview/action';
import { LOCALE_MAP } from 'src/utils/constants';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type ManageMfeProps = {
  country?: string;
  language?: string;
  stack?: number;
  authProvider: JarvisAuthProvider;
};

const localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  stack: 1,
  authProvider: null,
};

export const setProps = () => {
  const { authProvider, localization, stack } = useShellRootContext();
  localProps.authProvider = authProvider;
  localProps.language = localization.language;
  localProps.stack = stack ?? 1;
  localProps.country = localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(props.language, props.country);
  return assetsProvider.getText(`ecp-solutions-app-overview.${subkey}`, args);
};

export const isMockUpMode = () => {
  return !!(
    (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') &&
    localStorage.getItem('enable-devices-mockup-data')
  );
};

export const getMockUpData = async (moduleName: string) => {
  return await import(`src/mock/${moduleName}.json`);
};

export const refreshRootComponent = (dispatch) => {
  dispatch(storeStartRootComponent(false));

  setTimeout(() => {
    dispatch(storeStartRootComponent(true));
  }, 100);
};

export const getSupportedLang = (data) => {
  if (!data?.localeInfoList || data?.localeInfoList.length == 0) {
    return '--';
  }

  return data?.localeInfoList
    .map((it) => LOCALE_MAP[it.locale])
    .filter((it) => it != undefined)
    .join(', ');
};
