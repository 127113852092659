export const refined = (data: any) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    return data;
  }
};

export const refinedArray = (data) => {
  if (data === undefined || data === null) {
    return [];
  } else {
    return data;
  }
};
export const refinedPlatformType = (data) => {
  if (data === undefined || data === null || data === '') {
    return '--';
  } else {
    if (data === 'LinkForDevice') {
      return 'Workpath';
    } else {
      return 'Non-Workpath';
    }
  }
};
