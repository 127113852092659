import { getProps } from 'src/utils/commonMethods';
import { service } from 'src/utils/constants';
import { ContentBrokerClient, Stack } from '@jarvis/react-mfe-component/dist/src';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stg';
      case Stack.prod:
        return 'pro';
      default:
        return '';
    }
  };

  const getApiUrl = (hostPrefix: string, basePath: string): string => {
    const stackPrefix = '';
    const defaultBaseUrl = 'tropos-rnd.com';

    return (
      'https://' +
      (hostPrefix.length ? hostPrefix + '.' : '') +
      (stackPrefix.length ? stackPrefix + '-' : '') +
      defaultBaseUrl +
      basePath
    );
  };

  const getApiClient = (serviceName) => {
    let client;
    const contentBrokerEndpoint = getApiUrl(
      `stratus-${getStackPrefix(stack)}`,
      '/content-broker-api/v1',
    );

    switch (serviceName) {
      case service.contentBroker:
        client = new ContentBrokerClient(contentBrokerEndpoint, authProvider);
        break;
    }

    return client;
  };
  return {
    getApiClient,
  };
};
