import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import packageInfo from '../package.json';
import CustomThemeProvider from 'src/contexts/CustomThemeProvider/CustomThemeProvider';
import { App } from 'src';
import { Provider } from 'react-redux';
import { TShellProps } from 'src/types/TShellProps';
import { currentStack } from 'src/utils/constants';
import { configureStore } from '@reduxjs/toolkit';
import { ShellRootProvider } from 'src/contexts/ShellRoot';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { PermissionsContainer } from '../demo/PermissionsContainer';
import { ToastProvider, useToast, DirectionProvider } from '@veneer/core';

declare global {
  interface Window {
    Shell: TShellProps;
  }
}

declare const window: any;
export default function Root(props) {
  const { v1 } = window.Shell as TShellProps;
  const namespace = '@jarvis/react-ecp-solutions-app-overview';
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React);
  const [LDProvider, setLDProvider] = useState(null);

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
  });

  const getLDProvider = async () => {
    let clientID = '';
    const CURRENT_STACK = currentStack;

    if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
    else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
    else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
    else {
      //production
      clientID = '6232996c7927321456ef8f82';
    }
    const Provider = await asyncWithLDProvider({
      clientSideID: clientID,
    });
    return Provider;
  };

  const getTenantId = () => {
    const tenantIdsMap = props.tenantHandler.getTenantIdsMap();
    if (tenantIdsMap != undefined) {
      if (tenantIdsMap.stratusCustomer != undefined) {
        return tenantIdsMap.stratusOrganization + '__' + tenantIdsMap.stratusCustomer;
      } else {
        return tenantIdsMap.stratusOrganization;
      }
    }
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider();

      setLDProvider(() => Provider);
    };

    setProvider();
  }, []);

  return LDProvider ? (
    <section id={namespace}>
      <DirectionProvider direction={directionValue}>
        <ToastProvider>
          <LDProvider>
            <ShellRootProvider
              id={packageInfo.name}
              stack={props.stack}
              localization={v1.localization}
              showLastUpdatedSection={props.showLastUpdatedSection}
              useToast={useToast}
              events={v1.events}
              authProvider={v1.authProvider}
              navigation={v1.navigation}
              getBreadcrumb={v1.breadcrumbs.getBreadcrumbs}
              setBreadcrumb={v1.breadcrumbs.add}
              removeBreadcrumb={v1.breadcrumbs.remove}
              tenantId={getTenantId()}
              appUuid={props.appUuid ?? ''}
              theme={v1.theme}
              featureFlags={null}
            >
              <CustomThemeProvider>
                <Provider store={store}>
                  <App {...props} />
                  <PermissionsContainer />
                </Provider>
              </CustomThemeProvider>
            </ShellRootProvider>
          </LDProvider>
        </ToastProvider>
      </DirectionProvider>
    </section>
  ) : (
    <></>
  );
}
