import React, { useEffect, useState } from 'react';
import { Tabs } from '@veneer/core';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { NavLink, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { GlobalHeader, Overview } from 'src/components/organism';

type TMainTemplateProps = {
  appInfo: any;
};

const MainTemplate = (props: TMainTemplateProps) => {
  const {
    appUuid,
    navigation,
    getBreadcrumb,
    setBreadcrumb,
    removeBreadcrumb,
    showLastUpdatedSection,
  } = useShellRootContext();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const baseName = navigation.createHref({ pathname: `/solutions/app/${appUuid}` });

  useEffect(() => {
    function tabHandler() {
      setCurrentTab(0);
    }
    window.addEventListener('popstate', tabHandler);
    setBreadCrumbForTab(0);

    return () => {
      window.removeEventListener('popstate', tabHandler);
    };
  }, []);

  function setBreadCrumbForTab(tabPath) {
    if (getBreadcrumb().length > 0) {
      if (getBreadcrumb().at(-1).key != 'solutions') {
        removeBreadcrumb(getBreadcrumb().at(-1).key);
      }
      setBreadcrumb({
        text: `${props.appInfo.title}${tabPath == 1 ? ' : Devices' : ''}`,
        url: `${baseName}${tabPath == 1 ? '/devices' : ''}`,
        key: `menu-solutions-app-${appUuid}`,
      });
    }
  }

  const tabs = [];
  tabs.push({
    id: 0,
    label: (
      <StyledNavLink
        exact
        to={``}
        activeClassName="selected"
        data-testid="overview-tab"
        onClick={() => setBreadCrumbForTab(0)}
      >
        {t('tab.overview')}
      </StyledNavLink>
    ),
    description: '',
    content: (
      <Route path="">
        <Overview data-testid={'overview-container'} data={props.appInfo} />
      </Route>
    ),
  });

  return (
    <>
      {showLastUpdatedSection && currentTab !== 2 && <GlobalHeader title={props.appInfo.title} />}
      <Wrapper
        id="solutions-apps-overview-tabs"
        data-testid="root-component"
        currentTab={currentTab}
      >
        <Router basename={baseName}>
          <Switch>
            <Tabs
              controlId="extended"
              mode="extended"
              selectedTabId={currentTab}
              tabs={tabs}
              data-testid="devices-homepage-tabs"
              className={'app-overview-tab-list'}
            />
          </Switch>
        </Router>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: block;
  padding: 24px;

  .app-overview-tab-list {
    span {
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;

  &.selected {
    color: var(--hpBlue600);
  }
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: var(--hpBlue600);
  }
`;

export default MainTemplate;
