import React, { useMemo, useState } from 'react';
import ShellRootContext from './ShellRootContext';
import { Stack } from '@jarvis/react-mfe-component/dist/src';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ShellRootProvider = (props) => {
  const flags = useFlags();

  const [hpProps, setHpProps] = useState({
    stack: props.stack ?? Stack.pie,
    events: props.events,
    localization: props.localization,
    showLastUpdatedSection: props.showLastUpdatedSection ?? true,
    useToast: props.useToast(),
    authProvider: props.authProvider,
    navigation: props.navigation,
    getBreadcrumb: props.getBreadcrumb,
    setBreadcrumb: props.setBreadcrumb,
    removeBreadcrumb: props.removeBreadcrumb,
    tenantId: props.tenantId ?? '',
    customRelativePath: props.customRelativePath,
    appUuid: props.appUuid ?? '',
    permissionSet: props.permissionSet ?? [],
    theme: props.theme,
    featureFlags: flags,
  });

  const value = useMemo(() => ({ ...{ ...hpProps, setHpProps } }), [hpProps]);

  return <ShellRootContext.Provider value={value}>{props.children}</ShellRootContext.Provider>;
};
