import styled from 'styled-components';
import React from 'react';
import { Button, IconWarningAlt } from '@veneer/core';
import { t } from 'src/utils/commonMethods';

type TEmptyContainerProps = {
  retryAction?: () => void;
};

const EmptyContainer = (props: TEmptyContainerProps) => {
  const BtnStyle = { padding: '0', minWidth: '0' };

  return (
    <NoItemsArea data-testid={'empty-container'}>
      <IconWrapper>
        <IconWarningAlt size={24} />
      </IconWrapper>

      <div>{t('unable_to_load_data')}</div>

      <RetryWrapper>
        <Button appearance="ghost" onClick={props.retryAction} customStyle={BtnStyle}>
          {t('retry')}
        </Button>
      </RetryWrapper>
    </NoItemsArea>
  );
};

export default EmptyContainer;

const NoItemsArea = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const RetryWrapper = styled.div`
  margin-top: 10px;
`;
