import styled from 'styled-components';
import React from 'react';

const LabelText = ({ label, detail }) => {
  return (
    <SettingsWrapper>
      <SettingsLabel>{label}</SettingsLabel>
      <SettingsDetail>{detail}</SettingsDetail>
    </SettingsWrapper>
  );
};

export default LabelText;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const SettingsLabel = styled.div`
  color: var(--light-foreground-medium, #404040);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const SettingsDetail = styled.div`
  color: var(--light-foreground, #212121);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  align-self: stretch;
`;
