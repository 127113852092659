import React from 'react';
import styled from 'styled-components';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { CommonLoading } from 'src/components/atom';

const PermissionModal = (props: { permissionList; locale: string }) => {
  const { permissionSet } = useShellRootContext();

  const getLabel = (obj) => {
    if (Object.keys(obj.label).includes(props.locale)) {
      return obj.label[props.locale];
    }
    return obj.label['en_US'];
  };
  const getPermissions = () => {
    return props.permissionList.map((pl) => permissionSet.find((ps) => ps.permissionCode == pl));
  };

  // NOTE: permission is not showing if not in permissionSet
  if (!permissionSet) {
    return <CommonLoading />;
  }

  return (
    <>
      <Title>Permission Modal</Title>
      <Container data-testid={'permission-modal-container'}>
        {props.permissionList.length == 0 && <Text>{t(`modal.permission_empty`)}</Text>}
        {getPermissions().map((el, idx) => el && <List key={+idx}>{getLabel(el)}</List>)}
      </Container>
    </>
  );
};

export default PermissionModal;

const Title = styled.h4`
  margin-bottom: 20px;
`;

const Container = styled.ul``;

const List = styled.li`
  list-style: inside;
`;

const Text = styled.span``;
