import React, { useEffect } from 'react';
import { useS3Data } from 'src/utils/s3Utils';
import { useShellRootContext } from '../src/contexts/ShellRoot';
import { isMockUpMode } from '../src/utils/commonMethods';

export const PermissionsContainer = () => {
  const { setHpProps } = useShellRootContext();
  let { s3Data } = useS3Data(
    'https://docs.hpcommandcenter.com/scripts/contentMgt/min_permission_infos_list_230720_00.js',
    'permissionInfosList',
  );

  useEffect(() => {
    const getS3Data = async () => {
      if (isMockUpMode()) {
        s3Data = await import('src/mock/s3-permission-set.json');
        s3Data = JSON.stringify(s3Data.permissionSet);
      }
    };
    getS3Data().finally(() => {
      if (s3Data != null) {
        setHpProps((prev) => ({ ...prev, permissionSet: JSON.parse(s3Data) }));
      }
    });
  }, [s3Data]);

  return <div></div>;
};
