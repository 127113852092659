import { useApiClient } from 'src/api/useApiClient';
import { service } from 'src/utils/constants';
import { refinedGetAppInfo } from './contentBrokerRefinery';
import { getMockUpData, isMockUpMode } from 'src/utils/commonMethods';
import { getParsedS3managedAppJson } from 'src/utils/s3Utils';

/**
 * App Deploy API
 */
export const contentBrokerAPI = () => {
  const { getApiClient } = useApiClient();

  const client = getApiClient(service.contentBroker);
  const getAppInfo = async (filter) => {
    let error, response: any;

    if (isMockUpMode()) {
      await getMockUpData('static-icon-title-desc-others').then((res) => {
        response = refinedGetAppInfo(res.data);
      });
      return { response, error };
    }

    await client
      .getManagedApp(filter)
      .then((res) => {
        response = refinedGetAppInfo(getParsedS3managedAppJson(res.data));
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getAppInfo,
  };
};
