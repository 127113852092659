import React, { useRef, useState } from 'react';
import { Fallback } from 'src/components/molecule';
import { MainTemplate } from 'src/components/template';
import { contentBrokerAPI } from 'src/api/contentBroker';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TFallback = {
  setError: (boolean) => void;
};

export const MainPage = () => {
  const [appInfo, setAppInfo] = useState(null);

  const { getAppInfo } = contentBrokerAPI();
  const { appUuid } = useShellRootContext();

  const appInfoRef = useRef<TFallback>();
  const filter = {
    propertyName: 'uuid',
    propertyValue: [appUuid],
  };

  const apiGetAppInfo = async () => {
    const { response, error } = await getAppInfo(filter);
    if (error != undefined) {
      appInfoRef.current.setError(true);
    } else {
      setAppInfo(response?.[0] ?? []);
    }
  };

  return (
    <Fallback ref={appInfoRef} fetch={apiGetAppInfo} data-testid={'fallback__main-page'}>
      <MainTemplate appInfo={appInfo} />
    </Fallback>
  );
};
