import styled from 'styled-components';
import React, { useMemo } from 'react';
import { MainPage } from 'src/components/page';
import { setProps } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useConstructor } from 'src/hooks/useConstructor';
import { getDetailsStoreActions } from 'src/store/appsOverview/init';
export const App = (props) => {
  const { appsOverviewState } = useStoreState();
  const { startRootComponent } = appsOverviewState;

  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  // init all redux stores
  useConstructor(() => {
    setProps();

    dispatchAll(getDetailsStoreActions(props));
  });

  const didReduxUpdated = () => startRootComponent;

  const renderApp = useMemo(() => {
    if (didReduxUpdated()) {
      return (
        <Wrapper>
          <MainPage />
        </Wrapper>
      );
    } else {
      return <></>;
    }
  }, [didReduxUpdated()]);

  return <>{renderApp}</>;
};

const Wrapper = styled.div`
  background: #f8f8f8;
`;
