import { ThemeProvider } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import React, { useEffect, useState } from 'react';
import { getSystem } from 'src/styles/palette';

const CustomThemeProvider = ({ children }) => {
  const { featureFlags, theme } = useShellRootContext();
  const [themeProviderProps, setThemeProviderProps] = useState(
    theme?.getThemeProviderProperties?.(),
  );

  useEffect(() => {
    if (featureFlags.enableWexTheme) {
      setThemeProviderProps({ ...themeProviderProps, customSystem: getSystem() });
    }
  }, []);
  return <ThemeProvider {...themeProviderProps}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
