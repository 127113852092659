import { useState } from 'react';

export const getParsedS3managedAppJson = (s3Data) => {
  return s3Data.resourceList?.map((appObj) => {
    if (Object.values(appObj?.metadata)?.length === 0) {
      return;
    }
    const title = appObj?.name;
    let icon = '';
    let simplifiedAppID = '';
    const descriptionList = [];
    const screenshotList = [];
    const permissionList = [];

    Object.values(appObj?.metadata)?.forEach(
      (metaObj: {
        dataKind: string;
        downloadLink: string;
        locale: string;
        stringValue: string;
      }) => {
        if (metaObj.dataKind === 'SimplifiedAppID') {
          simplifiedAppID = metaObj.stringValue;
          return;
        }
        if (metaObj.dataKind === 'Icon62x62') {
          icon = metaObj?.downloadLink;
          return;
        }
        if (metaObj.dataKind === 'Screenshot') {
          screenshotList.push({ locale: metaObj.locale, downloadLink: metaObj.downloadLink });
          return;
        }

        if (
          metaObj.stringValue === '' ||
          metaObj.stringValue === undefined ||
          metaObj.stringValue === null
        ) {
          return;
        }
        if (metaObj.dataKind === 'Description') {
          descriptionList.push({ locale: metaObj.locale, description: metaObj.stringValue });
          return;
        }
        if (metaObj.dataKind === 'UsesPermissions') {
          JSON.parse(metaObj.stringValue)
            ?.map((obj) => obj.id)
            .forEach((permissionId) => {
              if (!permissionList.includes(permissionId)) {
                permissionList.push(permissionId);
              }
            });
        }
      },
    );

    const localeInfoList = [];

    descriptionList?.forEach((obj) => {
      const downloadLinkList = screenshotList
        .filter((e) => e.locale == obj.locale)
        .map((b) => b.downloadLink);

      localeInfoList.push({
        locale: obj.locale,
        description: obj.description,
        screenshotList: downloadLinkList,
      });
    });

    return {
      appUuid: appObj.uuid,
      title,
      icon,
      localeInfoList,
      simplifiedAppID,
      supportLink: appObj.webSite ?? '',
      platformType: appObj.platformType ?? '',
      platformVersion: appObj.platformVersion ?? '',
      appSize: `${(appObj.sizeBytes / 1000000).toFixed(2)} MB`,
      isFree: appObj.licenseType !== 'Licensed',
      permissionList,
    };
  });
};

export const useS3Data = (s3url, sessionStorageKey) => {
  const [s3Data, setS3Data] = useState(null);
  const externalScript = document.createElement('script');

  externalScript.setAttribute('src', s3url);
  document.head.appendChild(externalScript);

  externalScript.onload = () => {
    setS3Data(sessionStorage.getItem(sessionStorageKey));
  };

  return {
    s3Data,
  };
};
