export const store = {
  appsOverview: {
    START_ROOT_COMPONENT: 'app-overview/startRootComponent',
  },
};

export const dateFormat = {
  default: 'MMM DD, YYYY hh:mm:ss A', // March 17th, 2022 02:45:30 PM (12 hours format)
};

export const service = {
  contentBroker: 'contentBroker',
};

export const LOCALE_MAP = {
  da_DK: 'Danish',
  de_DE: 'German',
  en_US: 'English',
  es_ES: 'Spanish',
  fr_FR: 'French',
  it_IT: 'Italian',
  ja_JP: 'Japanese',
  ko_KR: 'Korean',
  nl_NL: 'Dutch',
  pl_PL: 'Polish',
  pt_PT: 'Portuguese',
  ru_RU: 'Russian',
  sv_SE: 'Swedish',
  zh_CN: 'Chinese Traditional',
  zh_TW: 'Chinese Taiwan',
};

export const URL = {
  HP_Privacy_Statement: 'https://contentmgt.dp.smartcloudprint.com/en_US/ag-ps.html',
  End_User_License_Agreement:
    'https://d5bl54l33ylvh.cloudfront.net/public/02f6122d-dde7-4f35-9d9a-8fdda06b4ba2/en_US/47ab5789-5514-4532-9049-6aa190bb056f',
};

const origin = {
  ECP_LOCAL: 'http://ecp.local',
  DEV: 'https://ecp.dev.portalshell.int.hp.com',
  PIE: 'https://ecp.pie.portalshell.int.hp.com',
  STAGE: 'https://ecp.stage.portalshell.int.hp.com',
  PROD: 'https://hp-commandcenter.com',
};

export const currentStack = {
  DEV: window.location.origin === origin.DEV,
  LOCAL: window.location.origin.includes(origin.ECP_LOCAL),
  PIE: window.location.origin === origin.PIE,
  STAGE: window.location.origin === origin.STAGE,
  PROD: window.location.origin === origin.PROD,
};
