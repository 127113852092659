import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@veneer/core';
import { PermissionModal } from 'src/components/molecule';
import { refinedArray } from 'src/utils/refinedMethods';
import { getProps } from 'src/utils/commonMethods';

export type TAppData = {
  appUuid: string;
  title: string;
  icon: string;
  simplifiedAppID: string;
  supportLink: string;
  platformType: string;
  platformVersion: string;
  appSize: string;
  isFree: boolean;
  localeInfoList: any[];
  permissionList: string[];
};

const ModalGroup = forwardRef((props: { data: TAppData }, ref) => {
  useImperativeHandle(ref, () => ({
    openModal(type) {
      setOpenModals((prev) => ({ ...prev, [type]: true }));
    },
  }));

  const [openModals, setOpenModals] = useState({
    appSupport: false,
    permission: false,
    visitSupport: false,
    terms: false,
  });

  const { language, country } = getProps();
  const currentLocale = `${language}_${country.toUpperCase()}`;

  const closeModal = (evt) => {
    const target =
      evt.target instanceof HTMLButtonElement ? evt.target.parentNode.parentNode : evt.target;
    const type = target?.getElementsByClassName('vn-modal--body')[0].id.split('-')[0];

    setOpenModals((prev) => ({ ...prev, [type]: false }));
  };

  return (
    <Wrapper data-testid={'modal-group-container'}>
      <Modal
        id="permission"
        data-testid="permission-modal"
        show={openModals.permission}
        onClose={closeModal}
        footer={
          <Button data-testid={'modal-close-button'} onClick={closeModal}>
            Close
          </Button>
        }
      >
        <PermissionModal
          locale={currentLocale}
          permissionList={refinedArray(props.data?.permissionList)}
        />
      </Modal>
    </Wrapper>
  );
});
ModalGroup.displayName = 'ModalGroup';

const Wrapper = styled.div``;

export default ModalGroup;
